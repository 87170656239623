import strings from "./strings";
import ServiceSection from "../layouts/Service";
import NFTSection from "../layouts/NFT";
import TeamSection from "../layouts/Team";
import PartnerSection from "../layouts/Partner";
import RoadmapSection from "../layouts/Roadmap";

import charles from "../assets/web.image.team_charles.svg";
import eggy from "../assets/web.image.team_eggy.svg";
import eric from "../assets/web.image.team_eric.svg";
import harry from "../assets/web.image.team_harry.svg";
import justin from "../assets/web.image.team_justin.svg";
import mia from "../assets/web.image.team_mia.svg";
import mini from "../assets/web.image.team_mini.svg";
import rose from "../assets/web.image.team_rose.svg";
import wilson from "../assets/web.image.team_wilson.svg";

export const values = {

    breakpoint: "lg",

    sections: [
        {
            short: strings.home,
        },
        {
            short: strings.logo_name,
            layout: <ServiceSection />,
            noContainer: true,
        },
        {
            title: strings.nft_title,
            description: strings.nft_description,
            layout: <NFTSection />,
            bgColor: "#f8f9fa",
        },
        {
            short: strings.aboutus,
            title: strings.team,
            description: strings.team_description,
            layout: <TeamSection />
        },
        {
            title: strings.partner,
            layout: <PartnerSection />,
            bgColor: "#f8f9fa",
        },
        {
            short: strings.roadmap,
            title: strings.roadmap,
            layout: <RoadmapSection />,
            bgColor: "#1a1a1a",
            isDark: true,
        },
    ],

    serviceContents: [
        {
            title: strings.trade,
            description: strings.trade_description,
            image: "https://picsum.photos/500"
        },
        {
            title: strings.creation,
            description: strings.creation_description,
            image: "https://picsum.photos/500/400"
        },
        {
            title: strings.exhibition,
            description: strings.exhibition_description,
            image: "https://picsum.photos/400/600"
        },
        {
            title: strings.community,
            description: strings.community_description,
            image: "https://picsum.photos/500/1000"
        },
    ],

    nftContents: [
        {
            title: strings.transform,
            image: "https://picsum.photos/400"
        },
        {
            title: strings.creation,
            image: "https://picsum.photos/600/400"
        },
        {
            title: strings.other,
            image: "https://picsum.photos/600"
        },
    ],

    teamContents: [
        {
            name: "Charles",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: charles,
        },
        {
            name: "Eggy",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: eggy,
        },
        {
            name: "Eric",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: eric,
        },
        {
            name: "Harry",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: harry,
        },
        {
            name: "Justin",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: justin,
        },
        {
            name: "Mia",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: mia,
        },
        {
            name: "Mini",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: mini,
        },
        {
            name: "Rose",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: rose,
        },
        {
            name: "Wilson",
            sub: "SUB",
            detail: "Write_Something_Here",
            image: wilson,
        },
    ]

}