import * as React from 'react';

import Stack from '@mui/material/Stack';
import {useTheme} from "@mui/styles";
import AbsoluteBox from "./AbsoluteBox";
import ServiceTabs from "./ServiceTabs";
import PetBox from "./Pet";

function ServiceSection() {
    const theme = useTheme();

    return (
        <Stack direction={"column"} spacing={25} alignItems={"center"}>
            <PetBox />
            <AbsoluteBox />
            <ServiceTabs/>
        </Stack>
    );
}

export default ServiceSection;