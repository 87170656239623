const strings = {
    logo_name: "iSOBOX",

    /* Section */
    home: "home",
    pet: "pet",
    service: "service",
    vision: "vision",
    nft: "nft",
    team: "TEAM",
    partner: "Partner",
    roadmap: "Roadmap",
    wallet: "wallet",
    nft_title: "NFT in iSOBOX",

    whatsisobox: "What's iSOBOX",
    aboutus: "About us",

    whitelist: "whitelist",

    /* Home */
    main_description: "The BOX contains my universe",
    total_quantity: "전체 수량",
    mint: "민팅하기",
    comming_soon: "Comming Soon",
    total_funding: "Total Funding",
    day: "day",
    hour: "hour",
    min: "min",
    sec: "sec",
    connect_wallet_before_minting: "민팅하려면 지갑을 연결하세요",

    /* iSOBOX */
    pet_title1: "BOX the original",
    pet_title2: "가지는것은 <e>특별한 의미</e>입니다.",
    pet_description: "총 7개의 파츠로 구성된 개성이 넘치는 단 하나의 조합으로, 당신은 이 모습으로 변신할 수 있게됩니다. \n" +
        "“BOX the original”을 소유하면 우리와 함께할 수 있는 거버넌스 권한 또한 가지게 됩니다. \n" +
        "우리가 흥한다면, 당신은 더 큰 베네핏을 얻게 될지도 모릅니다. 이 기회를 놓치지 마세요!",

    isobox_description: "<e>아이소박스</e>에는 당신을 나타낼 아바타와 공간이 있습니다. \n" +
        "당신은 무엇이든 될 수 있고 어디든 갈 수 있죠. \n" +
        "공간에는 나의 일상을 담은 게시물, 취향을 반영한 기능들, \n" +
        "그리고 개성을 표현해줄 NFT도 멋지게 전시할 수 있습니다. \n" +
        "또한 다양한 공간속에서 사람들을 만나고, 커뮤니티를 형성 할 수도 \n" +
        "있습니다. 원하는 누구든 아이템을 창작할 수 있고, 거래도 가능합니다. \n" +
        "누구나 쉽게 즐길 수 있는 아이소박스로 당신, 입장할 준비 됐나요?",

    trade: "Trade",
    creation: "Creation",
    exhibition: "Exhibition",
    community: "Community",
    trade_description: "다양한 커뮤니케이션 도구와 행아웃 할 수 있는 \n" +
        "컨텐츠를 제공합니다. 소셜활동을 즐길 수 있고 \n" +
        "공간이라는 유대감을 가진 커뮤니티를 구성할 수 \n" +
        "있습니다.",
    creation_description: "다양한 커뮤니케이션 도구와 행아웃 할 수 있는 \n" +
        "컨텐츠를 제공합니다. 소셜활동을 즐길 수 있고 \n" +
        "공간이라는 유대감을 가진 커뮤니티를 구성할 수 \n" +
        "있습니다.",
    exhibition_description: "다양한 커뮤니케이션 도구와 행아웃 할 수 있는 \n" +
        "컨텐츠를 제공합니다. 소셜활동을 즐길 수 있고 \n" +
        "공간이라는 유대감을 가진 커뮤니티를 구성할 수 \n" +
        "있습니다.",
    community_description: "다양한 커뮤니케이션 도구와 행아웃 할 수 있는 \n" +
        "컨텐츠를 제공합니다. 소셜활동을 즐길 수 있고 \n" +
        "공간이라는 유대감을 가진 커뮤니티를 구성할 수 \n" +
        "있습니다.",


    /* NFT */
    nft_description: "아이소박스에서 발행한 NFT, 유저가 창작한NFT, 그리고 가지고있는 어떤 NFT든 개성을 표현해줄 요소가 됩니다. \n" +
        "당신이 가진 자산들을 멋지게 보여줄 기회입니다!",
    transform: "Transform",
    other: "Other",

    /* Team */
    team_description: "우리는 다양한 개성과 역량을 발휘하며 열정적으로 협업합니다\n",


    /* Roadmap */
    roadmap_title_1: "정식 버전 런칭",
    roadmap_description_1: "• 폴리곤 네트워크에 기반한 50000개의 유일한 변신NFT를 발행합니다.\n" +
        "• iSOBOX공식 홈페이지를 오픈하여 민팅진행과 프로젝트 백서 공개.\n" +
        "• NFT커뮤니티 결속력 강화를 위한 플랫폼 별 커뮤니티 운영.",
    roadmap_title_2: "마켓플레이스 런칭",
    roadmap_title_3: "아바타 커스터마이징 툴 배포",

    /* Footer */
    whitepaper: "white paper",
    contact_us: "contact us",
    social_media: "social media",
}

export default strings;