import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import strings from "../../res/strings";
import {Button, Stack} from "@mui/material";
import {styled, useTheme} from "@mui/styles";
import {ContentCopyRounded} from "@mui/icons-material";

const Root = styled(Stack)(({theme}) => ({
    width: "100%",
    maxWidth: "400px",
    padding: 24,
    boxSizing: "border-box",
    "borderRadius": "24px",
    "boxShadow": "10px 10px 18px 0 #e5e6e7, -10px -10px 18px 0 #fff",
    "backgroundColor": "#fafafa"
}));

function MintBox({account, ...props}) {
    const theme = useTheme();

    return (
        <Box onClick={props.toggle}>
            <Root
                direction={"column"}
                spacing={4}
            >

                <Typography
                    variant={"h4"}
                    fontWeight={"bold"}
                    align={"center"}
                >
                    "MINT YOUR NFT"
                </Typography>

                <div>
                    <Typography variant={"subtitle1"}>
                        {strings.total_quantity}
                    </Typography>
                    <Typography variant={"h5"} fontWeight={"bold"}>
                        21,213,937
                    </Typography>
                </div>

                <div>
                    <Typography variant={"subtitle1"}>
                        {strings.total_quantity}
                    </Typography>
                    <Typography variant={"h5"} fontWeight={"bold"}>
                        21,213,937
                    </Typography>
                </div>

                <div>
                    <Typography variant={"subtitle1"}>
                        {strings.total_quantity}
                    </Typography>
                    <Typography variant={"h5"} fontWeight={"bold"}>
                        21,213,937
                    </Typography>
                </div>

                <div>
                    <Button
                        variant={"contained"}
                        size={"large"}
                        color={"primary"}
                        disableElevation
                        disableRipple
                        fullWidth
                        style={{
                            "height": "60px",
                            "borderRadius": "16px"
                        }}
                        disabled={!account}
                    >
                        {account ? strings.mint : strings.connect_wallet_before_minting}
                    </Button>

                    {account &&
                        <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                            justifyContent={"center"}
                            pt={1}
                        >
                            <Typography
                                variant={"subtitle2"}
                                color={"primary"}
                                style={{
                                    "whiteSpace": "nowrap",
                                    "overflow": "hidden",
                                    "textOverflow": "ellipsis",
                                    "maxWidth": "200px",
                                    "text-overflow-start": "clip",
                                    "text-overflow-middle": "ellipsis",
                                    "text-overflow-end": "clip",
                                    "text-overflow-min-width": "0.3ch"
                                }}
                            >
                                {`${account.slice(0, 6)}...${account.slice(-6)}`}
                            </Typography>

                            <ContentCopyRounded fontSize={"small"} color={"primary"}/>
                        </Stack>
                    }
                </div>
            </Root>
        </Box>
    );
}

export default MintBox;