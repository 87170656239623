import * as React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";

import Stack from '@mui/material/Stack';
import {useTheme} from "@mui/styles";
import Box from "@mui/material/Box";
import {Container, Typography, useMediaQuery} from "@mui/material";
import strings from "../../res/strings";
import {values} from "../../res/values";
import {useState} from "react";

import gif1 from "../../assets/web.image_anim.minting01.gif"
import gif2 from "../../assets/web.image_anim.minting02.gif"
import gif3 from "../../assets/web.image_anim.minting03.gif"

const contents = [
    {
        source: gif1,
        style: {
            "opacity": "0.4",
            "backgroundImage": "linear-gradient(230deg, #fbbb78, #ff9530 100%)"
        }
    },
    {
        source: gif2,
        style: {
            "opacity": "0.5",
            "boxShadow": "0 8px 20px 10px rgba(141, 147, 215, 0.2)",
            "backgroundImage": "linear-gradient(230deg, #8d93d7, #2c37ab 100%)"
        }
    },
    {
        source: gif3,
        style: {
            "opacity": "0.4",
            "backgroundImage": "linear-gradient(230deg, #c7e3de, #5b9186 100%)"
        }
    },

]

function Item({item}) {
    return (
        <>
            <Stack
                direction={"row"}
                alignItems={"end"}
                justifyContent={"end"}
                position={"absolute"}
                bottom={0}
                left={0}
                right={0}
                m={1.5}
                zIndex={1}
            >
                <img src={item.source} width={"100%"} height={"100%"}/>
            </Stack>

            <Box
                position={"absolute"}
                bottom={0}
                left={0}
                right={0}
                borderRadius={"32px"}
                style={{
                    aspectRatio: "220/186",
                    ...item.style
                }}
            />
        </>
    )
}

function PetBox() {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <Container maxWidth={values.breakpoint} style={{padding: mobile && 0}}>

            <Stack direction={"column"} spacing={4} margin={"0 auto"} width={"100%"}>

                <Box>
                    <Typography variant={"h4"} fontWeight={900} align={"center"} pb={1}>
                        {strings.pet_title1}
                    </Typography>
                    <Typography fontSize={"1.3rem"} fontWeight={500} align={"center"}>
                        {strings.pet_title2}
                    </Typography>
                </Box>

                {mobile ?
                    <Swiper
                        spaceBetween={-10}
                        slidesPerView={"auto"}
                        centeredSlides={true}
                        loop={true}
                        onActiveIndexChange={swiper => setActiveIndex(swiper.realIndex)}
                    >
                        {contents.map((item, index) =>
                            <SwiperSlide
                                style={{
                                    position: "relative",
                                    aspectRatio: "4/5",

                                    boxSizing: "border-box",
                                    maxWidth: "220px",
                                    transition: ".3s",
                                    transform: (activeIndex === index) ? 'scale(1)' : 'scale(0.7)',
                                    opacity: (activeIndex === index) ? 1 : .6,
                                }}
                            >
                                <Item item={item}/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    :
                    <Stack direction={"row"} spacing={4} px={breakpoint ? 3 : 10}>
                        {contents.map((item, index) =>
                            <Box
                                flex={1}
                                position={"relative"}
                                sx={{aspectRatio: "4/5"}}
                            >
                                <Item item={item}/>
                            </Box>
                        )}
                    </Stack>
                }

                <Typography
                    variant={"subtitle1"}
                    whiteSpace={!breakpoint && "pre-line"}
                    align={"center"}
                    lineHeight={2}
                    fontSize={breakpoint ? "1rem" : "1.125rem"}
                    px={mobile && 2}
                >
                    {strings.pet_description}
                </Typography>

            </Stack>

        </Container>
    );
}

export default PetBox;