import * as React from 'react';
import Stack from '@mui/material/Stack';
import {Box, Button, Container, Typography, useMediaQuery} from "@mui/material";
import strings from "../../res/strings";
import {styled, useTheme} from "@mui/styles";
import MintBox from "../../components/MintBox";
import {values} from "../../res/values";
import CountDownTimer from "../../components/CountDownTimer";
import {useState} from "react";

const Root = styled(Stack)({
    // color: 'white',
    maxWidth: 700,
    // height: "100vh",
    paddingTop: 150,
    paddingBottom: 150,
    boxSizing: "border-box",
});

function Home(props) {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.down('md'));

    const [mode, setMode] = useState(1);

    return (
        <Container maxWidth={values.breakpoint}>
            <Root
                id={"home"}
                direction={"column"}
                spacing={breakpoint ? .3 : 1}
                pt={19}
            >
                <Typography
                    fontWeight={900}
                    letterSpacing={"normal"}
                    fontSize={breakpoint ? theme.typography.h2.fontSize : 80}
                    lineHeight={!breakpoint && 1.1}
                    sx={{
                        "-webkit-text-stroke": "2px #2b231e",
                    }}
                >
                    {strings.logo_name}
                </Typography>

                <Typography
                    variant={breakpoint ? "h6" : "h5"}
                    color={theme.palette.text.secondary}
                >
                    {strings.main_description}
                </Typography>

                <Box minHeight={32} />

                {mode === 0 ? <MintBox toggle={() => setMode(1)} {...props}/> : <CountDownTimer toggle={() => setMode(0)} />}
            </Root>
        </Container>
    );
}

export default Home;