import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Arrow({color, fontSize, ...props}) {
    return (
        <SvgIcon viewBox={"0 0 48 48"} color={color} fontSize={fontSize} {...props}>
            <g>
                <rect className="cls-1" fill={"#ed6f27"} x="3" y="3" width="42" height="42" rx="11"/>
                <path className="cls-2" fill={"#f7b188"}
                      d="M34,4A10.011,10.011,0,0,1,44,14V34A10.011,10.011,0,0,1,34,44H14A10.011,10.011,0,0,1,4,34V14A10.011,10.011,0,0,1,14,4H34m0-2H14A12,12,0,0,0,2,14V34A12,12,0,0,0,14,46H34A12,12,0,0,0,46,34V14A12,12,0,0,0,34,2Z"/>
            </g>
            <path className="cls-3" fill={"#fff"}
                  d="M35.664,25.109c.032-.048.054-.1.081-.149a1.815,1.815,0,0,0,.1-.2,2.009,2.009,0,0,0,.062-.2,1.717,1.717,0,0,0,.051-.173,1.99,1.99,0,0,0,0-.784,1.717,1.717,0,0,0-.051-.173,2.009,2.009,0,0,0-.062-.2,1.815,1.815,0,0,0-.1-.2c-.027-.05-.049-.1-.081-.149a1.988,1.988,0,0,0-.25-.306l-6-6a2,2,0,1,0-2.829,2.828L29.173,22H14a2,2,0,0,0,0,4H29.173l-2.587,2.586a2,2,0,0,0,2.829,2.828l6-6A1.988,1.988,0,0,0,35.664,25.109Z"/>
        </SvgIcon>
    );
}

export default Arrow;